<template>
  <div id="Video" v-show="videoCallSilverState">
    <!-- notificationDrawer  -->
    <div style="position: relative; height: 100%">
      <notification-drawer ref="notification">
        <template v-slot:notificationDrawerCloseButton>
          <v-btn icon color @click.stop="closeVideoCall">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </notification-drawer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Video",
  data() {
    return {
      notificationDrawer: false,
    };
  },
  components: {
    NotificationDrawer: () =>
      import("../../layouts/common-drawer/NotificationDrawer.vue"),
  },
  computed: {
    ...mapState({
      videoCallSilverState: (state) => state.videoCallSilver.videoData,
      callData: (state) => state.loggedUserSilver.callData,
      pollingData: (state) => state.pollingWorkplaceSilver.polling,
    }),
  },
  watch: {
    videoCallSilverState(val) {
      if (!val) return;
      this.openVideoCall();
    },
    callData(call) {
      if (call && call.extraFields?.Videollamada) {
        this.videoChange(true);
        this.openVideoCall();
      } else {
        this.closeVideoCall();
      }
    },
  },
  methods: {
    ...mapActions(["videoChange"]),
    openVideoCall() {
      // if (this.$root.autoCall && !this.$root.acceptTurnAuto) {
      //   return;
      // }

      if (this.callData && this.callData.extraFields?.Videollamada) {
        navigator.mediaDevices.getUserMedia({ video: true });
        this.notificationDrawer = true;
        this.showVideoCallComponent();
      }
    },

    showVideoCallComponent() {
      setTimeout(() => {
        if (this.$refs.notification)
          this.$refs.notification.open(this.callData);
        else {
          this.showVideoCallComponent();
        }
      }, 1000);
    },

    closeVideoCall() {
      this.videoChange(false);
      this.notificationDrawer = false;
      if (this.$refs.notification) this.$refs.notification.close();
    },
  },
};
</script>

<style scoped>
#Video {
  transform: translateX(500px);
  transition: 0.5s transform;
  animation: fadeIn 1s ease-in-out;
  animation-delay: 1s;
  animation-iteration-count: 1;
  max-height: calc(100vh - 179px);
  max-width: 30%;
  overflow-y: auto;
}

@keyframes fadeIn {
  0% {
    transform: translateX(500px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
